/*=====================
  
  Contact Section Css

==========================*/
@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?3qkin2");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?#iefix3qkin2")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.woff?3qkin2")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.ttf?3qkin2")
      format("truetype"),
    url("fhttps://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.svg?3qkin2#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}


.contact_section_tagline {
  margin-bottom: 45px;
  font-size: 22px;
  text-transform: capitalize;
  color: $text-primary;
}

.contact_section_contact {
  color: $text-primary;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 22px;
}

.contact_section_contact:hover {
  color: $shadow;
}



@media all and (min-width: 1400px) {
  .contact_section_contact {
    font-size: 28px !important;
  }
  .contact_section_contact h2 {
    letter-spacing: 2px;
  }
  .contact_section_contact img {
    font-size: 18px !important;
  }
  .contact_section_tagline {
    font-size: 40px !important;
  }
}
