footer {
  background: $background-screen;
  padding: 5px 100px;
  width: 100%;
  border-radius: 0;
  position: relative;
}
footer .content .content_logo {
  width: 100px;
  margin-right: 10px;
}
footer .content .techfolio_copyright {
  font-size: 18px;
  font-weight: bold;
}