.section{
  position: relative
}
a {
  text-decoration: none;
}
.btn {
  letter-spacing: -1.12px;
  font-weight: 700;
  font-size: 18px;
  transition: 0.4s;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.wrapper {
  min-height: 100vh;
  font-weight: 300;
  color: #fff;
  position: relative;
  background-color: $background-primary;
}

section {
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden !important;
  background-color: $background-primary;
  //display: block;
  //width: 100%;
  box-sizing: border-box;
  padding: 30px 30px;
  min-height: 95vh;
  @include media-breakpoint-down(md) { 
    padding: 50px 30px;
    min-height: unset;
  }
}
.section_illustration {
  width: 40vw;
}
.emoji {
  width: 30px;
  margin-right: 10px !important;
}
.site-container {
  width: 95vw;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.site-layout-content {
  padding: 24px;
  min-height: 280px;
}



/*
.wrap-image,
.wrap-default {
  position: relative;
}

.wrap-image img{
  max-width: 100% !important;
}

.wrap-default img {
  width: 90vh !important;
  margin-right: -200px;
}

.wrap-image::before,
.wrap-image::after,
.wrap-default::before,
.wrap-default::after {
  content: "";
  width: 100%;
  border: 2px solid $shadow;
  transition: all 0.25s ease-out;
}
.wrap-image::before,
.wrap-default::before {
  background-color: $shadow;
  top: -15px;
  left: -30px;
}
.wrap-image::after,
.wrap-default::after {
  bottom: -15px;
  left: 30px;
}
.wrap-image:hover::before,
.wrap-default:hover::before {
  top: 15px;
  left: 30px;
}
.wrap-image:hover::after,
.wrap-default:hover::after {
  bottom: 15px;
  left: -25px;
}*/

.col-left{
  border-right: 1px solid rgba(0,0,0,.1);
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
  @media only screen and (min-width: 1500px) and (max-width: 2000px){ 
    flex-basis: 24% !important;
    max-width: 24% !important;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1500px){ 
    flex-basis: 30% !important;
    max-width: 30% !important;
  }
  @media only screen and (min-width: 920px) and (max-width: 1200px){ 
    display: none !important;
  }
}

.col-main{
  @media only screen and (min-width: 1500px) and (max-width: 2000px){ 
    flex-basis: 76% !important;
    max-width: 76% !important;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1500px){ 
    flex-basis: 70% !important;
    max-width: 70% !important;
  }
  @media only screen and (min-width: 920px) and (max-width: 1200px){ 
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}








