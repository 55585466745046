.service-items {
  width: 100%;	
}

.service-items .service-item {
  vertical-align: top;
  text-align: left;
  margin-bottom: 30px;
  padding: 0 10px;
  box-sizing: border-box;
}

@media (max-width:840px) {
  .service-items .service-item {
    
  }
}

@media (max-width:580px) {
  .service-items {
    margin-left: 0
  }
  .service-items .service-item {
    margin-left: 0;
    padding: 0 0px;
  }
}

@media (max-width:400px) {
  .service-items .service-item {
    text-align: center
  }
}

.service-items .service-item .icon {
  float: left;
  width: 36px;
  text-align: center;
  font-size: 30px;
  color: $primary;
  margin-right: 5px;
}

@media (max-width:400px) {
  .service-items .service-item .icon {
    float: none;
    display: inline-block;
    vertical-align: middle
  }
}

.service-items .service-item .icon .ion {
  display: block
}

.service-items .service-item .name {
  font-weight: 500;
  font-size: 1.25em;
  color: #141414;
  /*margin: 6px 0 0 46px*/
}

@media (max-width:400px) {
  .service-items .service-item .name {
    margin: 0 0 0 2px;
    display: inline-block;
    vertical-align: middle
  }
}

.service-items .service-item p {
  margin-bottom: 0
}

.section-service{
  .title{
      
  }
  .service-items{
    .service-item{
      .icon{
        font-size: 30px;
        float: left;
        margin-right: 15px;
        text-align: center;
        p{
          margin: 0;
        }
      }
      .name{
        color: $text-primary;
        font-size: 1.4em;
      }
      .txt{
        color: $text-secondary;
        font-size: 1.2em;
      }
    }
  }
}