.about_section{
  position: relative;
  overflow: hidden; 

  .carousel{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    opacity: .5;
    z-index: 1;
    .custom-img {
      height: 100vh;
      object-fit: cover;
    }
  }

  .bg-black{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    //background-color: rgba(0, 0, 0, 0.8) !important;
    background: linear-gradient(to right, $gradient1,$gradient2,$gradient3);
    //opacity: .6;
    opacity: .8;
  }

  .started{
    position: relative;
    z-index: 4;
    *{
      position: relative;
      z-index: 4;
    }

    
    .wrap-title .h-title,
    .wrap-title .h-subtitles,
    .wrap-content .desc .txt,
    .wrap-content .desc .info-list li span,
    .wrap-content .desc .info-list li a{
      color: #fff !important; 
      text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
    }
    .wrap-title .h-subtitles h1{
      color: #fff !important;
      text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
      .react-typewriter-pointer{
        background-color: #fff !important;
      }
    }



    .wrap-title{
      .h-title{
        font-size: 3em;
        font-weight: 500;
        line-height: 1.2em;
        margin: 0 0 15px;
        letter-spacing: 0;
        text-transform: none;
        //color: $text-primary;
        text-align: center;
      }
      .h-subtitles{
        color: $text-secondary;
        h1{
          //color: $text-secondary !important;
        }
      }
    }
    .wrap-content{
      position: relative;
      z-index: 3;
      .photo{
        width: 100%;
        margin: 25px 0 10px 0;
        @include media-breakpoint-up(lg) {
          display: none;
        }
        img{
          display: block;
          margin: 0 auto;
        }
      }
      .info-list {
        margin-bottom: 35px;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: inline-block;
            vertical-align: top;
            width: 32%;
            margin: 12px 0 0;
            font-size: 1.25em;
            @media (max-width:840px) {
              width: 48%;
            }
            @media (max-width:580px) {
              width: 100%;
            }
            strong {
              font-weight: 400;
              color: $primary;
            }
            a{
              color: #7c8aad;
            }
          }
        }
      }
      .desc{
        margin-top: 2em;
        text-align: left;
        .txt{
          color: $text-secondary;
          font-size: 1.2em;
        }
        .info-list{
          color: $text-secondary;
          font-size: 1.2em;
          li{
            display: inline-block;
            width: 33.33%;
            margin: 12px 0 0;
            vertical-align: top;
            box-sizing: border-box;
            padding-left: 20px;
            @media (max-width: 1360px) {
              width: 50%;
            }
            @include media-breakpoint-down(md) { 
              width: 100%;
            }
            strong, span, p{
              display: inline;
            }
            p{
              margin: 0;
            }
          }
        }
      }
    }
  }
}


