




.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, width 0.35s;
  z-index: 50;

  &:before {
    content: "";
    background: $shadow;
    left: -55%;
    top: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    transition: left 0.35s ease;
  }
  &:after {
    content: "";
    background: $shadow;
    right: -55%;
    top: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    transition: all 0.35s ease;
  }
  &.open{
    opacity: 0.9;
    visibility: visible;
    height: 100%;
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    li {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;
      a{
        cursor: pointer;
      }
      &:nth-of-type(2) {
        animation-delay: 0.40s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.45s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.55s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.60s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.65s;
      }
      &:nth-of-type(7) {
        animation-delay: 0.70s;
      }
      &:nth-of-type(8) {
        animation-delay: 0.75s;
      }
      &:nth-of-type(9) {
        animation-delay: 0.80s;
      }
      &:nth-of-type(10) {
        animation-delay: 0.85s;
      }
      &:nth-of-type(11) {
        animation-delay: 0.90s;
      }
    }
  }
  
  


  nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 35px;

    font-weight: 400;
    text-align: center;
    z-index: 100;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
    li {
      display: block;
      height: 20%;
      height: calc(100% / 15);
      min-height: 30px;
      position: relative;
      opacity: 0;
      p {
        display: block;
        position: relative;
        color: #fff;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        margin: .5em 0;
        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: #fff;
          transition: 0.35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}















.customNav_2__trigger {
  display: block;
  position: relative;
  margin: 0 7px;
  width: 68px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border-radius: 100px;
  background-color: #00a2ff;
  font-size: 24px;
  text-transform: uppercase;
  cursor: pointer;
  //justify-content: center;
  //align-items: center;
  letter-spacing: normal;
  z-index: 100;
  position: relative;
  &:hover{
    //transition: transform 0.4s ease-out;
    //transform: rotateZ(360deg);
    box-shadow: 0 2px 15px $shadow !important;
  }
}

@media screen and (max-width: 991.99px) {
  .customNav_2__trigger {
    display: flex;
  }
}

.customNav_2__trigger .navbar-sticky.sticky {
  right: 30px;
  opacity: 1;
}

.customNav_2__trigger .navbar-sticky li {
  width: 54px;
  height: 54px;
  background-color: rgba(0, 16, 45, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0 0 10px 0;
  
}

.customNav_2__trigger .navbar-sticky li:nth-child(2) {
  right: -60px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.customNav_2__trigger .navbar-sticky.sticky li:nth-child(2) {
  right: 0;
  opacity: 1;
}

.customNav_2__trigger .navbar-sticky li:nth-child(3) {
  background-color: rgba(46, 104, 255, 0.8);
  right: -90px;
  opacity: 0;
  transition: all 1s ease-in-out;
  display: none;
}

.customNav_2__trigger .navbar-sticky.sticky li:nth-child(3) {
  right: 0;
  opacity: 1;
}

.customNav_2__trigger .navbar-sticky a, 
.customNav_2__trigger .navbar-sticky a:visited {
  color: #fff;
  text-decoration: none;
}

.customNav_2__trigger .navbar-sticky__button {
  position: relative;
  width: 20px;
  height: 19px;
  /*height: 25px;*/
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  top: 23px;
}

.customNav_2__trigger .line {
  position: absolute;
  width: 20px;
  height: 3px;
  border-radius: 20px;
  transition: all 0.25s ease-in-out;
  background-color: #ffffff;
  /*background-color: $primary-dark;*/
}

.customNav_2__trigger .line:nth-child(1),
.customNav_2__trigger .line:nth-child(3) {
  transition: all 0.5s ease-in-out;
}

.customNav_2__trigger .line:nth-child(1) {
  left: 0;
  top: 0;
}

.customNav_2__trigger .line:nth-child(2) {
  /*top: calc(50% - 2.5px);*/
  top: calc(50% - 1.5px);
  left: -5px;
}

.customNav_2__trigger{
  &:hover{
    .navbar-sticky__button{
      .line:nth-child(2) {
        left: 0;
      }
    }
  }
} 

.customNav_2__trigger .line:nth-child(3) {
  left: 0;
  bottom: 0;
}

.customNav_2__trigger.active-menu .navbar-sticky li {
  background-color: none;
}

.customNav_2__trigger.active-menu .navbar-sticky li:nth-child(2),
.customNav_2__trigger.active-menu .navbar-sticky li:nth-child(3) {
  display: none;
}

.customNav_2__trigger.active-menu .line:nth-child(1) {
  transform: rotate(45deg);
  left: 1px;
  top: 8px;
  box-shadow: 0 0 12px 0 #ffffff;
}

.customNav_2__trigger.active-menu .line:nth-child(3) {
  transform: rotate(-45deg);
  left: 1px;
  bottom: 8px;
  box-shadow: 0 0 12px 0 #ffffff;
}

.customNav_2__trigger.active-menu .line:nth-child(2),
.customNav_2__trigger.active-menu .navbar-sticky__button:hover .line:nth-child(2) {
  width: 55px;
  height: 55px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: none;
  left: -20px;
  top: -20px;
  -webkit-animation: active-menu .4s .25s forwards;
  animation: active-menu .4s .25s forwards;
  opacity: 0;
}














.site-header {
  background-color: $background-primary;
  border-bottom: 1px solid rgba(0,0,0,.1);
  box-sizing: border-box;
  padding: 10px 0;
  .social-networks{
    margin-bottom: 0;
    text-align: right;
  }
  
}

.social-networks {
  margin: 0 0 35px;
  padding: 0;
  font-size: 0;
  li {
    display: inline-block;
    vertical-align: top;
    span, a{
      transition: all 0.5s ease-in-out;
    }
  }
  .ham {
    z-index: 100;
    position: relative;
    //margin: 10px 10px;
    width: 68px;
    height: 68px;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    background-color: $btn;
    cursor: pointer;
    color: #fff;
    @include media-breakpoint-down(lg) {
      margin: 10px 10px;
      width: 50px;
      height: 50px;
    }
  }
  .ham:hover,
  .ham:focus {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .hamBurger {
    font-size: 26px;
    margin-top: 20px;
    margin-right: 23px;
    @include media-breakpoint-down(lg) {
      font-size: 26px;
      margin-top: 12px;
      margin-right: 14px;
    }
  }
  .lang-link{
    display: block;
    position: relative;
    margin: 0 7px;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border-radius: 100px;
    background-color: #00a2ff;
    font-size: 24px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
      //transition: transform 0.4s ease-out;
      //transform: rotateZ(360deg);
      box-shadow: 0 2px 15px $shadow !important;
    }
  }
  .lang-link,
  .customNav_2__trigger{
    @media (max-width: 520px) {
      width: 55px !important;
      height: 55px !important;
      line-height: 55px !important;
      //font-size: 24px;
      font-size: 20px !important;
      .navbar-sticky__button{
        top: 18px !important;
      }
    }
    @media (max-width: 430px) {
      width: 50px !important;
      height: 50px !important;
      line-height: 50px !important;
      font-size: 18px !important;
      .navbar-sticky__button{
        top: 16px !important;
      }
    }
  }
  .social-network-link {
    display: block;
    position: relative;
    margin: 0 7px;
    width: 68px;
    height: 68px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    text-decoration: none;
    @include media-breakpoint-down(lg) {
      margin: 0 7px;
      width: 50px;
      height: 50px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      margin-top: -50%;
      margin-left: -50%;
      border-radius: 100px;
      background-color: $btn;
      transition: all 400ms;
    }
    &:after {
      position: relative;
      display: block;
      font-family: "icomoon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #fff;
      font-size: 26px;
      line-height: 66px;
      @include media-breakpoint-down(lg) {
        font-size: 26px;
        line-height: 50px;
      }
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  
  .spin-icon a:hover:after,
  .spin-icon a:focus:after {
    transition: transform 0.4s ease-out;
    transform: rotateZ(360deg);
  }
  .icon-linkedin:after {
    content: "";
  }
  .icon-linkedin:hover:before,
  .icon-linkedin:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .icon-facebook:after {
    content: "";
  }
  .icon-facebook:hover:before,
  .icon-facebook:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .icon-twitter:after {
    content: "";
  }
  .icon-twitter:hover:before,
  .icon-twitter:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .icon-github:after {
    content: "";
  }
  .icon-github:hover:before,
  .icon-github:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .icon-instagram:after {
    content: "";
  }
  .icon-instagram:hover:before,
  .icon-instagram:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
  .icon-menu:after {
    content: "";
  }
  .icon-menu:hover:before,
  .icon-menu:focus:before {
    box-shadow: 0 2px 15px $shadow !important;
  }
}
 




