.col-left{
  .photo{
    margin: 30px auto;
    display: table;
    height: 200px;
    position: relative;
    z-index: 1; 
  }
  .side_nav_heading {
    font-size: 2rem;
  }
 
  .nav__wrapper {
    //overflow: hidden !important;
    position: fixed;
    padding: 0px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    z-index: 100;
    height: 70%;
    height: calc(100% - 200px);
    position: fixed;
    z-index: 100;
    .wrap-nav {
      margin: 0 0 140px 20px;
      .nav__counter {
        font-size: 22px;
        transition: all 0.15s ease-out;
      }
      
      .nav__title {
        //font-size: 1.5rem;
        font-size: 1.1em;
        font-weight: 300;
        margin: 0 0 1rem;
        overflow: hidden;
        //opacity: 0;
        transition: height 0.3s ease-out, opacity 0.2s ease-out;
        letter-spacing: 2px;
        font-style: italic;
        //position: fixed;
      }
      .nav__title--stroke {
        -webkit-text-stroke-width: 1.5px;
        -moz-text-stroke-width: 1.5px;
        //-webkit-text-stroke-color: $text-primary;
        // -moz-text-stroke-color: $text-primary;
        //color: transparent;
        //color: $text-primary;
      }
      .nav__title--shadow {
        //text-shadow: 6px 6px $shadow;
      }

      li {
        position: relative;
        transition: all 0.3s ease-out;
        margin-bottom: 2.2rem;
        list-style: none;
        //@media (max-width: 1199.98px) {
        //@include media-breakpoint-down(xl) { 
        @media (max-width: 1300px) {
          margin-bottom: 1.5rem;
        }
        @media (max-width: 1150px) {
          margin-bottom: 1.2rem;
        }
      }
      li::after {
        content: "";
        display: block;
        border-left: 2px solid $text-secondary;
        border-top: 2px solid $text-secondary;
        height: 250px;
        width: 20px;
        position: absolute;
        left: -30px;
        top: 15px;
      }
      li {
        &.active {
          pointer-events: none;
          padding-left: 1em;
          &:after {
            width: 35px;
            height: 400px;
            //top: 35px;
          }
          .nav__counter {
            font-size: 36px;
          }
          .nav__title {
            font-size: 1.5rem;
            height: 40px;
            opacity: 1;
            overflow: visible;
            color: $shadow;
            font-size: 1.5em;
          }
          .nav__body {
            height: 100px;
            opacity: 1;
            overflow: visible;
          }
        }
        a{
          display: block;
          padding: 0;
          color: $text-primary;
          transition: all 0.15s ease-out;
          cursor: pointer;
          h3, span{
            color: $text-primary;
          }
          &:hover {
            background-color: transparent;
            padding-left: 1em;
            h3, span{
              color: $text-primary;
            }
          }
          &:focus {
            background-color: transparent;
            h3, span{
              color: $text-primary;
            }
          }
        }
      }
    }
  }
}