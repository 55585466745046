$halftone: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAHElEQVQYV2NUVFT8f//+fUYGBgYGMAEDcA5IBQCKJQVmn76DhAAAAABJRU5ErkJggg==");

// layout

//sizes
$logo-size: 2.5rem;

//.....
$white: #ffffff;
$dark: #616161;
$gray: #72757e;
$gray-1: #a4b0be;
$gray-2: #ced6e0;
$themed: #7f5af0;
$yellow: #feab32;



//$background-primary: #16161a;
$background-primary: #fff;

//$background-secondary: #7f5af0;
$background-secondary: #00a2ff;

//$background-screen: #242629;
$background-screen: #00a2ff;

//$text-primary: #fffffe;
$text-primary: #000;

$text-secondary: #94a1b2;

//$shadow: #7f5af0;
$shadow: #00a2ff;

//$btn: #1f2027;
$btn: #00a2ff;



$success-1: #7bed9f;
$success-2: #25bc63;
$error-1: #ff6b81;
$error-2: #ff4757;
$subtle-gray: #f3f3f4;
$subtle-gray-2: #dfe4ea;
$shadow-dark: #212121;
$border: #ebedef;



$temp-color: #1F4D79;
$primary: #00a2ff;
$green: $temp-color;
$blue: $primary;
$browm: $temp-color;
$lead: $primary;
$orange: $temp-color;


$gradient1: #2a314994;
$gradient2: #2a3149bf;
$gradient3: #2a3149e3;



/*Same bootstrap*/
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-sm-max: 767.99px;
$breakpoint-md: 768px;
$breakpoint-md-max: 991.99px;
$breakpoint-lg: 992px;
$breakpoint-lg-max: 1199.99px;
$breakpoint-xl: 1200px;




/*Small devices (landscape phones, 576px and up)*/
$phone-max: $breakpoint-sm-max;
@media (max-width: $phone-max) {  }

/*Medium devices (tablets, 768px and up)*/
$tablet-min: $breakpoint-md; $tablet-max: $breakpoint-md-max;
@media (max-width: $tablet-min) {  }
 
/*Large devices (desktops, 992px and up)*/
$resize-min: $breakpoint-lg; $resize-max: $breakpoint-lg-max;
@media (max-width: $resize-min) {  }

/*Extra large devices (large desktops, 1200px and up)*/
$desktop-min: $breakpoint-xl;
@media (max-width: $desktop-min) {  }