.sc-scroll-down {
  position: absolute;
  z-index: 10;
  bottom: 1.6em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  font-weight: bold;
  text-shadow: 1px 1px #45a29e;
  color: #fff;
  -webkit-animation: 1s linear .5s infinite normal none running MoveUpDown;
  animation: 1s linear .5s infinite normal none running MoveUpDown;
  &.dark{
    color: #000;
  }
  @include media-breakpoint-down(md) { 
    display: none;
  }
  .turn {
    font-size: 40px;
    cursor: pointer;
  }
}
@-webkit-keyframes MoveUpDown{
  50%{
    bottom:40px;
  }
}

@keyframes MoveUpDown{
  50%{
    bottom:40px;
  }
}


/*
.sc-scroll-down{
  position: absolute !important;
  bottom: 0 !important;
  z-index: 10 !important;
  bottom: 1.6em;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  font-weight: bold;
  text-shadow: 1px 1px #45a29e;
  color: #fff;
  animation: 3s linear 1s infinite normal none running MoveUpDown;
  .turn {
    cursor: pointer;
    font-size: 30px;
    transform: rotate(90deg);
  }
}
@keyframes MoveUpDown{
  0%, 100%{
  }
  50%{
      bottom:80px;
  }
}*/