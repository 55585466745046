//import 'bootstrap/dist/css/bootstrap.min.css';
//@import 'node_modules/bootstrap/scss/functions';
//@import 'node_modules/bootstrap/scss/variables';
//@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/bootstrap';

@import "base/variables";
@import "base/common";

@import "layout/app";
@import "layout/header";
@import "layout/footer";
@import "layout/sidebar";
@import "layout/navbar";

@import "widget/jump-scroll";
@import "widget/scroll-down";

@import "sections/about";
@import "sections/about2";
@import "sections/codingSkill";
@import "sections/contact";
@import "sections/designSkill";
@import "sections/experience";
@import "sections/history";
@import "sections/languageSkill";
@import "sections/project";
@import "sections/services";
@import "sections/skills";
@import "sections/whatIDo";
@import "sections/whyChooseMe";
@import "sections/work";


#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  font-family: "Titillium Web", sans-serif;
  color: $text-primary;
  /*
  *{
    color: $text-primary;
  }*/
}
