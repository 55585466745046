.section-skills-language{
  width: 100%;  
  .title{

  }
  .skills{
    ul{
      li{
        .name{
          color:$text-secondary;
          font-size: 1.2em;
        }
        .progress{

        }
      }
    }
  }
}