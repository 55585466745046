.about_section {
  font-size: 16px;
}
.about_section .container {
  text-align: center;
}
.about_section .container .device {
  display: inline-block;
  margin: 0 auto;
}
.about_section .container .device .frame {
  padding: 30px 2px 2px;
  border-radius: 10px;
  background: #ddd;
  box-shadow: 0 0 0 1px #ccc;
}
.about_section .container .device .frame .window-content {
  padding: 30px 10px 0px 3px;
  text-align: start;
  font-size: 16px;
}
.about_section .container .device .frame .window-content .line {
  font-size: 18px;
  color: #ffffff;
}
.about_section .container .device .frame .window-content span {
  font-size: 18px;
}

.line span {
  color: $shadow;
}

.screen {
  position: relative;
  height: 430px;
  width: 740px;
  border-radius: 0 0 10px 10px;
  background: $background-screen;
  box-shadow: inset 0 0 0 1px $background-screen;
}
.screen::before,
.screen::after {
  position: absolute;
  margin-left: -16px;
  content: " ";
  background: $background-primary;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
}
.screen::before {
  width: 10px;
  height: 10px;
  top: -20px;
  left: 20px;
  background: #ff6464;
  box-shadow: 15px 0 #64ff64, 30px 0 #ffff64;
}
.screen::after {
  width: 90%;
  height: 16px;
  top: -23px;
  left: 10%;
  border-radius: 5px;
  background: #eee;
}
.container,
.device,
.frame,
.screen {
  transition: all 0.5s ease;
}

/* @media all and (min-width: 600px) {
  .screen {
    height: 440px;
    width: 360px;
  }
  .screen:before {
    width: 10px;
    height: 10px;
    top: -30px;
    margin-left: -5px;
  }
} */
@media all and (max-width: 450px) {
  .frame {
    padding: 26px 2px 40px;
  }
  .screen {
    height: 60vh;
    width: 340px;
  }
  .screen:before {
    top: -22px;
    left: 21px;
  }
  .screen:after {
    width: 80%;
    height: 12px;
    top: -22px;
    left: 24%;
  }
  .about_section .container .device .frame .window-content .line {
    font-size: 14px;
  }
  .about_section .container .device .frame .window-content span {
    font-size: 14px;
  }
  .window-content {
    padding-top: 14px;
  }
}

@media all and (max-width: 360px) {
  .screen {
    height: 60vh;
    width: 300px;
  }
}

/* Desktop */
@media all and (min-width: 960px) {
  .line {
    font-size: 18px;
  }
  .frame {
    padding: 30px 2px 2px;
    border-radius: 10px;
  }
}
@media all and (min-width: 1400px) {
  .frame {
    padding: 30px 2px 2px;
    border-radius: 10px;
  }
  .screen {
    height: 600px;
    width: 1000px;
    border-radius: 0 0 10px 10px;
  }
  .screen:before {
    width: 12px;
    height: 12px;
    top: -20px;
    left: 15px;
    background: #ff6464;
    box-shadow: 15px 0 #64ff64, 30px 0 #ffff64;
  }
  .screen:after {
    width: 90%;
    height: 16px;
    top: -23px;
    left: 10%;
    border-radius: 5px;
    background: #eee;
  }
  .about_section .container .device .frame .window-content .line {
    font-size: 22px;
  }
  .about_section .container .device .frame .window-content span {
    font-size: 22px;
  }
}


.why_choose_me_section{
    .title{
      
    }
    .service-items{
      .service-item{
        .icon{
          font-size: 30px;
          float: left;
          margin-right: 15px;
          text-align: center;
          p{
            margin: 0;
          }
        }
        .name{
          color: $text-primary;
          font-size: 1.4em;
        }
        .txt{
          color: $text-secondary;
          font-size: 1.2em;
        }
      }
    }
}