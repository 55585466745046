.history_section{
  padding: 30px 30px 120px 30px;
  @include media-breakpoint-down(md) { 
    padding: 30px 30px;
  }
}


.section.resume .sec-history{
  margin-bottom: 50px;
}

@media (max-width:840px) {
  .section.resume .cols .col.col-md:first-child {
    margin-bottom: 120px
  }
}

.resume-items .resume-item {
  position: relative;
  padding: 0 30px 60px
}

@media (max-width:840px) {
  .resume-items .resume-item {
    padding-right: 0
  }
}

.resume-items .resume-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 1px;
  height: 100%;
  background: $primary;
}
@media (max-width:120px) {
  .resume-items .resume-item:before {
    top: 5px;
  }
}
@media (max-width:840px) {
  .resume-items .resume-item:before {
    top: 4px;
  } 
}

.resume-items .resume-item:first-child {
  padding-top: 0
}

.resume-items .resume-item:last-child {
  padding-bottom: 0;
  margin-top: -1px
}

.resume-items .resume-item:last-child:before {
  display: none
}

.resume-items .resume-item .date {
  position: relative;
  //top: -10px;
  top: -8px;
  margin: 0 0 5px;
  display: inline-block;
  padding: 0 5px;
  height: 20px;
  line-height: 18px;
  font-weight: 500;
  font-size: 1em;
  color: $primary;
  border: 1px solid $primary
}

.resume-items .resume-item .date:before {
  content: '';
  width: 29px;
  height: 1px;
  position: absolute;
  left: -30px;
  top: 9px;
  background: $primary
}

.resume-items .resume-item .name {
  font-weight: 500;
  color: #141414;
  margin: 0 0 15px
}

.resume-items .resume-item p {
  margin: 0
}


.resume-items .resume-item .image {
  margin-bottom: 15px;
  max-width: 75px;
  font-size: 0
}

.resume-items .resume-item .image img {
  max-width: 100%
}

.section-resume{
  .resume-items{
    .resume-item{
      .date{

      }
      .name{
        color: $text-primary;
        font-size: 1.4em;
        font-weight: bold;
      }
      .single-post-text{
          .txt{
            color: $text-secondary;
            font-size: 1.3em; 
            /**{
              color: $text-secondary;
              font-size: 1.1em;
            }*/
          }
          ul>li {
            //font-size: inherit;
            font-weight: 400;
            position: relative;
            margin: 5px 0;
            padding: 0 0 0 18px;
          }
          ul>li:before {
            position: absolute;
            top: 13px;
            left: 0;
            width: 6px;
            height: 6px;
            margin: 0;
            content: "";
            border-radius: 4px;
            background: #00a2ff;
            -khtml-border-radius: 4px;
        }
        li{
          list-style: none;
        }
      }
    }
  }
}