

.hp-scroll {
  opacity: .8
}

.hp-scroll:hover {
  opacity: 1
}

@keyframes example {
  0% {
      transform: translateY(0)
  }

  50% {
      transform: translateY(-20px)
  }

  to {
      transform: translateY(4px)
  }
}

@keyframes pulse {
  0% {
      transform: translate(0)
  }

  50% {
      transform: translateY(10px)
  }

  to {
      transform: translate(0)
  }
}

.hp-scroll {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite
}
.jump-top {
  font-size: 1.5em;
  position: fixed;
  z-index: 100;
  right: 15px;
  bottom: 10px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  transition: all .25s ease-in-out;
  text-align: center;
  opacity: 0;
  color: #fff;
  border-radius: 50%;
  background-color: #00a2ff
}

.jump-top.scroll {
  opacity: .8
}

.jump-top.scroll:hover {
  opacity: 1
}