.logo {
  font-size: 2rem;
  letter-spacing: 2px;
  margin: 1rem 0;
  font-style: italic;
  //position: fixed;
  font-weight: bold;
  text-align: left;
  @media (max-width: 520px) {
    font-size: 1.6rem;
  }
  @media (max-width: 430px) {
    font-size: 1.4rem;
  }
}
.logo--stroke {
  -webkit-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-color: $text-primary;
  -moz-text-stroke-color: $text-primary;
  color: transparent;
  color: #000;
  font-weight: 700;
}
.logo--shadow {
  //text-shadow: 6px 6px $shadow;
}






.icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 12.5%;
  transform: translate(-50%, -50%);
}

.mouse-icon {
  position: relative;
}
.mouse-icon .mouse {
  width: 15px;
  height: 24.625px;
  background: transparent;
  border-radius: 20px;
  position: relative;
  margin-bottom: 3px;
  border: 2px solid $text-primary;
}
.mouse-icon .mouse:after {
  content: "";
  background: $text-primary;
  width: 2px;
  height: 4px;
  position: absolute;
  top: 5px;
  left: 58%;
  margin-left: -2px;
  border-radius: 20px;
  -webkit-animation: scrollAnim 1.1s infinite;
  animation: scrollAnim 1.1s infinite;
}
.mouse-icon .arrow {
  width: 3px;
  height: 3px;
  display: block;
  border-bottom: 2px solid $text-primary;
  border-right: 2px solid $text-primary;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  margin: 0 auto;
  -webkit-animation: arrowAnim 1.2s infinite;
  animation: arrowAnim 1.2s infinite;
}
.mouse-icon .arrow.arrow-01 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.mouse-icon .arrow.arrow-02 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.mouse-icon .arrow.arrow-03 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes scrollAnim {
  from {
    top: 5px;
  }
  to {
    top: 15px;
    opacity: 0;
  }
}

@keyframes scrollAnim {
  from {
    top: 5px;
  }
  to {
    top: 15px;
    opacity: 0;
  }
}
@-webkit-keyframes arrowAnim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrowAnim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hand-icon {
  position: relative;
}

.hand {
  background: #fff;
  width: 4px;
  height: 12.5px;
  border-radius: 20px;
  position: relative;
  left: 125px;
  margin-bottom: 17px;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  -webkit-animation: handAnim 1.2s infinite;
  animation: handAnim 1.2s infinite;
}
.hand:after {
  content: "";
  background: #fff;
  width: 17px;
  height: 18px;
  border-radius: 4px 8px 38px 15px;
  -webkit-transform: rotate(6deg) skewY(10deg);
  transform: rotate(6deg) skewY(10deg);
  position: absolute;
  top: 13px;
  left: -1px;
}
.hand:before {
  content: "";
  background: #fff;
  width: 6px;
  height: 17px;
  border-radius: 2px 40px 20px 20px;
  position: absolute;
  top: 12px;
  left: -6px;
  -webkit-transform: rotate(-38deg);
  transform: rotate(-38deg);
}
.hand .circle {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -7px;
  opacity: 0.5;
  -webkit-animation: circleAnim 1.2s infinite 2.1s;
  animation: circleAnim 1.2s infinite 2.1s;
}
@-webkit-keyframes handAnim {
  0% {
    top: 20px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -20px;
    opacity: 0;
  }
}

@keyframes handAnim {
  0% {
    top: 20px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -20px;
    opacity: 0;
  }
}
@-webkit-keyframes circleAnim {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes circleAnim {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}