.skills-design {
  width: 100%;
}
.skills ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.skills ul li .name {
  font-size: 1.25em;
  font-weight: 400;
  position: relative;
  margin: 0 0 9px
}

.skills ul li .progress {
  display: block;
  height: 3px;
  position: relative;
  width: 100%;
  background: #ddd
}

.skills ul li .progress .percentage {
  left: 0;
  top: 0;
  position: absolute;
  height: 3px;
  width: 0;
  background: $primary;
  transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s
}

.skills ul li .progress .percentage .percent {
  position: absolute;
  top: -27px;
  right: 0;
  font-size: 1.25em;
  font-weight: 400;
  display: none;
}

.skills.dotted ul li .progress .percentage {
  position: relative;
  overflow: hidden;
  top: 0;
  height: 14px;
  background: transparent!important;
  z-index: 2
}

.design_skills_section{
  .title{

  }
  .skills{
    ul{
      li{
        .name{
          color:$text-secondary;
          font-size: 1.4em;
        }
        .progress{

        }
      }
    }
  }
}











