.work-cv{
  width: 100%;
}

.section.works .filters {
  margin-bottom: 30px
}

.section.works .filters input {
  display: none
}

.section.works .filters .btn-group {
  display: inline-block;
  vertical-align: top
}

.section.works .filters label {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  margin-bottom: 10px;
  font-size: 1.25em;
  color: #363636;
  cursor: pointer;
  position: relative;
  padding-bottom: 0
}

.section.works .filters label.glitch-effect {
  color: $primary
}


.wproject{

}
.wproject:hover{
	text-decoration: none;
}
.project{
	/*background-color: $primary;*/
	background-color: #eef0f8;
	//margin-bottom: 4vh;
	margin-bottom: 30px;
	position: relative;
	display: block;
	overflow: hidden;
	border-radius: 20px;
	box-shadow: 0 20px 30px rgba(185,185,200,.5);
	@include media-breakpoint-down(md) { 
		margin-bottom: 50px;
	}
	.project-cover{
		width: 100%;
		padding-top: 120%;
		background-size: 100% auto;
		background-position: top center;
		position: relative;
		z-index: 1;
	}
	.project-bg{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #000;
		opacity: 0;
		z-index: 2;
	}
	.project-info-plus{
		position: absolute;
		z-index: 2;
		top: 5%;
		width: 90%;
		margin: 0 5%;
		background-color: #00a2ff;
		box-sizing: border-box;
		border-radius: 10px;
		padding: 25px;
		opacity: 0;
		transform: scale(.5, .5);
		-webkit-transform: scale(.5, .5);
		-moz-transform: scale(.5, .5);
		-o-transform: scale(.5, .5);
		.ico{
			text-align: center;
			font-size: 2em;
			color: #fff;
			margin: 0 0 30px 0;
		}
		.cont{
			color: #fff;
			.cont-clie,
			.cont-tech{
				margin: 0 0 1.5em 0;
			}
			.cont-clie{

			}
			.cont-tech{

			}
			.cont-serv{

			}
			.wt,
			.wc{
				letter-spacing: -.02em;
			}
			.wt{
				width: 100%;
				margin: 0 0 0 0;
				font-weight: 700;
				font-size: 1.1em;
			}
			.wc{
				font-weight: 300;
				font-size: 1em;
			}
		}
	}
	.project-info{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		overflow: hidden;
		padding: 20px 20px;
		z-index: 3;
		.project-info-bg{
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left: 0;
			background-color: $primary;
			opacity: .98;
			z-index: 1;
		}
		.project-info-cont{
			position: relative;
			z-index: 2;
			color: #fff;
			.top{
				margin: 0 0 0 0;
				.title{
					color: #fff;
					letter-spacing: -.03em;
					font-weight: 700;
					font-size: 1.4em;
					line-height: 1.4em;
					text-align: center;
					margin: 0 0 0 0;
				}
				.subcontent{
					color: #fff;
					display: none;
				}
			}
		}

	}
}

/*.project:not(:hover) .cont {
	font-size: 0;
	height: 0;
	transition: font-size .25s,
				height .25s
               	opacity .5s .25s;
}*/


.project:hover{
	/*transform: translateY(-15px);*/
	text-decoration: none;
	.project-bg{
		opacity: .7;
	}
	.project-info-plus{
		opacity: .98 !important;
		transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		-moz-transform: scale(1, 1);
		-o-transform: scale(1, 1);
		.cont{

		}
	}
	.project-cover{
		transform: scale(1.1);
	}
	/*.project-info-cont{
		.top{
			margin: 0 0 1.5em 0;
			.title{
				text-align: left;
				font-size: 1.8em;
				line-height: 1.4em;
			}
			.subcontent{
				display: block;
			}
		}
		.cont{
			height: auto;
			transition: font-size .25s,
						height .25s
						opacity .5s .25s;
		}
	}*/
}